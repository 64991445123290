.embed-responsive-img-slider {
    padding-bottom: calc(31 / 49 * 100%);
}


.img-slider,
.img-slider__item {
    position: relative;
}

/* fullscreen btn */
.img-slider__fullscreen-btn {
    position:absolute;
    font-size: calc(55rem/16);
    color: var(--color-primary);
    z-index: 2;
    right:0;
    top:0;
    padding: calc(20rem/16);
    cursor:pointer;
}
.img-slider__fullscreen-btn img {
    opacity: 0;
    color: var(--color-primary);
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.slick-current .img-slider__fullscreen-btn img {
    opacity: 1;
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.img-slider__fullscreen-btn:hover img {
    transform: scale(1.2);
}


.img-slider .slick-dots {
    display: none !important;
    visibility: hidden;
}

/* custom arrows */
.img-slider__wrapper .slider__arrow.arrow-prev {
    left: 0;
    top: 50%;
    @media screen and (max-width: 1199px) {
        top: 42%;
    }
    @media screen and (max-width: 767px) {
        left: calc(25rem/16);
    }
}
.img-slider__wrapper .slider__arrow.arrow-next {
    right: 0;
    top: 50%;
    @media screen and (max-width: 1199px) {
        top: 42%;
    }
    @media screen and (max-width: 767px) {
        right: calc(25rem/16);
    }
}

/* img overlay */
@media screen and (min-width: 768px) {
    .img-slider .slick-list {
        overflow: visible;
    }
    .img-slider .img-slider__item {
        transform: translateX(-17%) scale(0.8);
        transition: transform 0.8s ease, opacity 0.8s ease;
        opacity: 0.8;
        position: relative;
    }
    .img-slider .slick-current + .slick-slide .img-slider__item {
        transform: translateX(17%) scale(0.8);
        transition: transform 0.8s ease, opacity 0.8s ease;
        opacity: 0.8;
    }
    .img-slider .slick-current .img-slider__item,
    .img-slider .slick-center .img-slider__item {
        transform: translate(0) scale(1);
        transition: transform 0.8s ease, opacity 0.8s ease;
        opacity: 1;
    }
    .img-slider .slick-slide {
        opacity: 1 !important;
    }

}
