.embed-responsive-thumb-slider {
    padding-bottom: calc(73 / 107 * 100%);
}

/* main slider */
.thumb-slider {
    position: relative;
}
.thumb-slider__main-item {
    position:relative;
}
.thumb-slider__main-item img {
    transition: all 1s cubic-bezier(0.4, 0, 0.3, 1);
    -webkit-backface-visibility: hidden;
    transform: scale(1.2);
}
.slick-active .thumb-slider__main-item img {
    transform: scale(1);
}

/* thumbs */
.thumb-slider__thumbs-wrapper {
    height: calc(140rem/16);
    margin-top: calc(20rem/16);

    @media screen and (max-width: 767px) {
        height: calc(60rem/16);
        margin-top: calc(10rem/16);
    }
}
.thumb-slider__thumbs .slick-current.slick-active {
    position: relative;
}
.thumb-slider__thumbs .slick-current.slick-active:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    width: 100%;
    height: calc(3rem/16);
}
.thumb-slider__thumbs .slick-slide {
    margin-right: calc(25rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-right: calc(45rem/16);
    }
}


/* fullscreen btn */
.thumb-slider__fullscreen-btn {
    position:absolute;
    font-size: calc(55rem/16);
    color: var(--color-primary);
    z-index: 2;
    right:0;
    top:0;
    padding: calc(20rem/16);
    cursor: pointer;
}
.thumb-slider__fullscreen-btn img {
    color: var(--color-primary);
    transition: transform 0.3s ease;
}
.thumb-slider__fullscreen-btn:hover img {
    transform: scale(1.2);
}


.thumb-slider .slick-dots {
    display: none !important;
    visibility: hidden;
}

.thumb-slider .thumb-slider__thumbs.slick-initialized .slick-slide {
    width: initial !important;
}
