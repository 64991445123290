.img-text-teaser {
    margin-top: calc(55rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(30rem/16);
    }
}
.img-text-teaser__img {
    position: relative;
}
.img-text-teaser__img::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(60, 63, 65);
    opacity: 0.15;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
}
.img-text-teaser__content {
    position: absolute;
    left: calc(-40rem/16);
    top: calc(35rem/16);
    transition: left 0.3s ease-in-out;

    @media screen and (max-width: 767px) {
        left: calc(30rem/16);
        bottom: calc(20rem/16);
        top: unset;
    }
}
.img-text-teaser__title {
    font-family: AileronBold, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: calc(44rem/16);
    line-height: calc(48rem/16);
    color: #000000;

    @media screen and (max-width: 767px) {
        font-size: calc(38rem/16);
        line-height: calc(42rem/16);
        margin-bottom: calc(8rem/16);
    }
}
.img-text-teaser__subtitle {
    font-size: calc(24rem/16);
    line-height: calc(29rem/16);
    color: #000000;
}

/* hover effects */
@media screen and (min-width: 1200px) {
    .img-text-teaser:hover .img-text-teaser__img::before {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    .img-text-teaser:hover .img-text-teaser__content {
        left: calc(0rem/16);
        transition: left 0.3s ease-in-out;
    }
}