/* Add your font-face rules here e.g: */
@font-face {
    font-family: 'AileronBold';
    src: url('./../../../static/css/fonts/Aileron-Bold.otf') format('otf');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'AileronBoldItalic';
    src: url('./../../../static/css/fonts/Aileron-BoldItalic.otf') format('otf');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'AileronHeavy';
    src: url('./../../../static/css/fonts/Aileron-Heavy.otf') format('otf');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oxygen';
    src: url('./../../../static/css/fonts/Oxygen.otf') format('otf');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'OxygenBold';
    src: url('./../../../static/css/fonts/Oxygen-Bold.otf') format('otf');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'OxygenItalic';
    src: url('./../../../static/css/fonts/Oxygen-Italic.otf') format('otf');
    font-weight: normal;
    font-display: swap;
}