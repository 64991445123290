@media screen and (min-width: 768px) {
    .wysiwyg-with-media--left .wysiwyg-with-media__media {
        margin-left: calc(100rem / 16);
        margin-right: 0;
    }

    .wysiwyg-with-media__media {
        margin-left: 0;
        margin-right: calc(100rem / 16);
    }

    .wysiwyg-with-slider__text {
        width: 80%;
    }
}