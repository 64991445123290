.textbox {
    position: relative;
    background-color: var(--color-light-grey);
    padding: calc(55rem/16) 0;
}
.textbox p {
    font-family: AileronBoldItalic, sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: calc(32rem/16);
    line-height: calc(42rem/16);
    margin-bottom: 0;
}
@media screen and (max-width: 767px) {
    .textbox p {
        font-size: calc(24rem/16);
        line-height: calc(28rem/16);
    }
}