.custom-checkbox {
    position: relative;
    padding-left: 1.7em;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 1.25em;
    height: 1.25em;
    margin-top: 0.12em;
}
.custom-checkbox__box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 30%;
    height: 90%;
    width: 40%;
    border-bottom: 3px solid var(--color-secondary);
    border-right: 3px solid var(--color-secondary);
    transform: rotate(45deg) skewX(10deg);
    color: var(--color-secondary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box::before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}


/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}