/* Table scaling */
@media screen and (min-width: 768px) and (max-width: 991px) {
    html {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    word-break: break-word;
}
@media screen and (min-width: 1200px) {
    body {
        line-height: var(--line-height-default);
    }
}

.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
.font-heading {
    font-family: var(--font-heading);
}
.font-heading--bold {
    font-family: var(--font-heading-bold);
    font-weight: bold;
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
address {
    font-style: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: var(--font-heading);
    margin-top: 0;
    display: block;
    color: inherit;
    font-weight: 800;
}

h1, .h1 {
    font-family: var(--font-heading-bold);
    font-size: calc(72rem/16);
    font-weight: bold;
}
@media screen and (min-width: 1200px) {
    h1, .h1 {
        line-height: 86px;
    }
}
@media screen and (max-width: 767px) {
    h1, .h1 {
        font-size: calc(40rem/16);
    }
}
h2, .h2 {
    font-size: calc(34rem/16);
    margin-bottom: calc(20rem/16);
}
@media screen and (max-width: 767px) {
    h2, .h2 {
        font-size: calc(28rem/16);
    }
}
h3, .h3 {
    font-size: calc(24rem/16);
}
h4, .h4 {
    font-size: calc(20rem/16);
}
h5, .h5 {
    font-size: calc(18rem/16);
}
h6, .h6 {
    font-size: 1em;
}

table{
    word-break: initial;
}