.wide-img-text-teaser {
    margin-top: calc(55rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(30rem/16);
    }
}
.wide-img-text-teaser__img {
    position: relative;
}
.wide-img-text-teaser__img::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: var(--color-secondary); */
    opacity: 0;
    z-index: 1;
    /* transition: opacity 0.3s ease-in-out; */
}
.wide-img-text-teaser__content {
    position: absolute;
    width: 80%;
    left: calc(0rem/16);
    top: calc(35rem/16);
    transition: left 0.3s ease-in-out;

    @media screen and (max-width: 767px) {
        position: relative;
        left: 0;
        top: unset;
        padding-top: calc(20rem/16);
    }
}
.wide-img-text-teaser__title {
    font-family: AileronBold, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: calc(44rem/16);
    line-height: calc(44rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(36rem/16);
        line-height: calc(38rem/16);
        margin-bottom: calc(8rem/16);
    }
}

/* hover effects */
@media screen and (min-width: 1200px) {
    .wide-img-text-teaser:hover {
        cursor: pointer;
    }
    /*.wide-img-text-teaser:hover .wide-img-text-teaser__img::before {
        opacity: 0.15;
        transition: opacity 0.3s ease-in-out;
    }*/
    .wide-img-text-teaser:hover .wide-img-text-teaser__content {
        left: calc(40rem/16);
        transition: left 0.3s ease-in-out;
    }
}
