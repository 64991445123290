.btn {
    border-radius: calc(90rem/16);
    padding: calc(8rem/16) calc(20rem/16);
}
.btn-wide {
    padding: calc(8rem/16) calc(120rem/16);
}

/* btn primary */
.btn-primary, .btn-default {
    color: var(--color-white);
}
.btn-primary:hover {
    background-color: var(--color-white);
    border-color: var(--color-primary);
    color: var(--color-primary);
}

/* btn secondary */
.btn-secondary {
    color: var(--color-white);
}
.btn-secondary:hover {
    background-color: var(--color-white);
    border-color: var(--color-secondary);
    color: var(--color-secondary);
}