.hero {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: calc(130rem/16);
    padding: 0;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        margin-bottom: calc(60rem/16);
    }
}

.hero__image {
    position: relative;
    width: 100%;
    height: calc(560rem/16);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 6s ease-out;

    @media screen and (max-width: 767px) {
        height: calc(300rem/16);
    }
}

.hero__image.is-in-viewport {
    transform: scale(1.05);
    transition: transform 6s ease-out;

}

.hero--tall .hero__image {
    height: 100vh;
}
/* grey overlay */
.hero__image::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 63, 65, 0.15);
    z-index: 1;
}
.hero__content {
    position: absolute;
    bottom: calc(30rem/16);
    z-index: 1;
}

.hero--tall .hero__content {
    bottom: calc(100rem/16);

    @media screen and (max-width: 767px) {
        bottom: calc(90rem/16);
    }
}


/* scroll down arrow */
.hero__scroll-down {
    display: block;
    position: absolute;
    margin: 0 auto;
    height: calc(40rem/16);
    bottom: calc(50rem/16);
    left: calc(50% - 15px);
    width: calc(60rem/16);
    z-index: 1;
}
.hero__scroll-down-arrow {
    position: absolute;
    padding: 0;
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    transform: rotate(135deg);
    bottom: calc(20rem/16);
    transition: 0.3s ease-in-out;
    animation: jump 3s infinite;
}
@media screen and (min-width: 768px) {
    .hero__scroll-down-arrow:hover {
        opacity: 0.7;
        transition: 0.3s ease-in-out;
    }
}

@keyframes jump {
    0% {
        top: 0;
    }
    50% {
        top: 30px;
    }
    100% {
        top: 0;
    }
}