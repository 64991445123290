.main-navbar {
    background-color: transparent;
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--navbar-height);
    z-index: 1000;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    @media screen and (max-width: 767px) {
        height: var(--mobile-navbar-height);
    }
}
/* navbar after scrolling */
.main-navbar.nav-solid {
    @media screen and (min-width: 768px) {
        background-color: var(--color-white);
        box-shadow: 0 6px 35px -13px var(--color-primary-light);
        transition: all 0.3s ease-in-out;
    }

}
.main-navbar.nav-solid .main-navbar__item .main-navbar__link {
    @media screen and (min-width: 768px) {
        color: var(--color-primary);
    }
}

/* logo */
.main-navbar__brand-wrapper {
    width: calc(270rem/16);
}
.main-navbar__brand-logo {
    width: 100%;
    height: auto;
}

/* links */
.main-navbar .main-navbar__item .main-navbar__link {
    font-family: var(--font-heading-bold);
    font-style: normal;
    font-weight: 800;
    font-size: calc(16rem/16);
    text-transform: uppercase;
    color: var(--color-white);
    padding: 0 0 0 calc(40rem/16);
    height: var(--navbar-height);
    line-height: var(--navbar-height);
    transition: color 0.2s ease-in-out;

    @media screen and (min-width: 1200px) {
        padding: 0 0 0 calc(80rem/16);
    }
}

/* second level */
.main-navbar__dropdown {
    @media screen and (min-width: 768px) {
        position: absolute;
        top: 100%;
        margin-left: calc(40rem/16);
        background: var(--color-white);
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        z-index: 100;
        visibility: hidden;
        box-shadow: 0 1rem 2rem -1rem rgba(0,0,0,.3);
    }
    @media screen and (min-width: 1200px) {
        margin-left: calc(80rem/16);
    }
}
.main-navbar__item:hover .main-navbar__dropdown {
    @media screen and (min-width: 768px) {
        visibility: visible;
    }
}
.main-navbar__dropdown-content {
    padding: 0 calc(20rem/16);
}
.nav-list__item {
     padding: calc(10rem/16) 0;
     border-bottom: 1px solid white;
     transition: color 0.2s ease-in-out,
     border-bottom-color 0.2s ease-in-out;
 }
.nav-list__item:hover {
    @media screen and (min-width: 768px) {
        color: var(--color-secondary);
        border-bottom-color: var(--color-primary);
        transition: color 0.2s ease-in-out,
                    border-bottom-color 0.2s ease-in-out;
    }
}

/* --------------------- MOBILE --------------------- */
@media screen and (max-width: 767px) {
    /* navbar to display block to prevent mobile bugs */
    .navbar.main-navbar {
        display: block;
    }

    /* button */
    .main-navbar__toggle {
        position: absolute;
        height: var(--mobile-navbar-height);
        top: 0;
        right: 0;
        padding: calc(30rem/16) calc(20rem/16);
        border-radius: 0;
        z-index: 10;
        transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    }
    .main-navbar__toggle:focus {
        outline: none;
    }
    .nav-solid .main-navbar__toggle {
        background-color: white;
        box-shadow: 0 3px 35px -8px var(--color-primary-light);
    }
    .nav-solid.open .main-navbar__toggle {
        box-shadow: none;
    }

    /* hamburger */
    .main-navbar__toggle span.main-navbar__toggle-icon,
    .main-navbar__toggle span.main-navbar__toggle-icon::before,
    .main-navbar__toggle span.main-navbar__toggle-icon::after {
        position: relative;
        display: block;
        width: calc(33rem/16);
        height: calc(4rem/16);
        background: var(--color-primary);
        border-radius: 3px;
        z-index: 1;
        transform-origin: calc(4rem/16) 0;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    .main-navbar__toggle span.main-navbar__toggle-icon::before {
        content: '';
        transform-origin: 0 0;
        top: calc(-9rem/16);
    }
    .main-navbar__toggle span.main-navbar__toggle-icon::after {
        content: '';
        transform-origin: 0 100%;
        top: calc(5rem/16);
    }

    /* transform hamburger to cross */
    .open .main-navbar__toggle span.main-navbar__toggle-icon::before {
        transform: rotate(45deg) translate(-3px, -2px);
    }
    /* Hide the middle one. */
    .open .main-navbar__toggle span.main-navbar__toggle-icon {
        background: transparent;
    }
    /* Last one into the other direction */
    .open .main-navbar__toggle span.main-navbar__toggle-icon::after {
        transform: rotate(-45deg) translate(-1px, 0);
    }


    /* main navi parts */
    .main-navbar__content {
        height: 100vh;
        background-color: var(--color-white);
        z-index: 1;
    }
    .main-navbar .main-navbar__item .main-navbar__link {
        color: var(--color-primary);
        height: calc(50rem/16);
        line-height: calc(50rem/16);
    }

    /* second level */
    .main-navbar__dropdown {
        margin-bottom: calc(14rem/16);
    }
    .main-navbar__dropdown-content {
        padding-left: calc(45rem/16);
        padding-right: calc(15rem/16);
    }
    .nav-list__item {
        font-size: calc(16rem/16);
        padding: calc(8rem/16) 0;
        border: none;
    }
    .nav-list__link::before {
        content: '';
        position: absolute;
        border-top: 2px solid var(--color-secondary);
        border-right: 2px solid var(--color-secondary);
        width: calc(10rem/16);
        height: calc(10rem/16);
        left: calc(40rem/16);
        margin-top: calc(7rem/16);
        transform: rotate(45deg);
    }

    /* logo */
    .main-navbar__brand-wrapper {
        width: calc(250rem/16);
        padding: calc(10rem/16) calc(20rem/16);
    }
    .mobile-navbar__brand-wrapper {
        position: fixed;
        top: calc(5rem/16);
        z-index: 0;
        display: block;
        width: calc(235rem/16);
        padding: calc(10rem/16) calc(20rem/16);
    }
    .mobile-navbar__brand-logo {
        width: 100%;
        height: auto;
    }
    .nav-solid .mobile-navbar__brand-wrapper {
        display: none;
    }
}
