.arrow {
    display: inline-block;
    width: calc(15rem/16);
    height: calc(15rem/16);
    border-top: 2px solid var(--color-primary);
    border-right: 2px solid var(--color-primary);
}
.arrow-top {
    transform: rotate(-45deg);
}
.arrow-right {
    transform: rotate(45deg);
}
.arrow-bottom {
    transform: rotate(135deg);
}
.arrow-left {
    transform: rotate(-135deg);
}


/* slider arrows */
.slider__arrow {
    color: var(--color-primary);
    background-color: var(--color-light-grey);
    position: absolute;
    z-index: 99;
    border: none;
    border-radius: 50%;
    width: calc(54rem/16);
    height: calc(54rem/16);
    transition: background-color 0.4s ease-in-out, color 0.4s ease;
    box-shadow: 0 0 0 rgba(20, 140, 177, 0.4);
    @media screen and (max-width: 767px) {
        width: calc(50rem/16);
        height: calc(50rem/16);
    }
}
.slider__arrow .arrow-left {
    margin-left: calc(5rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(3rem/16);
        margin-top: calc(7rem/16);
    }
}
.slider__arrow .arrow-right {
    margin-right: calc(5rem/16);
    @media screen and (max-width: 767px) {
        margin-right: calc(0rem/16);
        margin-left: calc(-3rem/16);
        margin-top: calc(7rem/16);
    }
}

.slider__arrow:not(.slick-disabled):hover {
    background-color: var(--color-secondary);
    animation: pulse-arrow 2s infinite;
}
.slider__arrow:not(.slick-disabled):hover .arrow {
    border-color: var(--color-white);
}

.slider__arrow.slick-disabled {
    border-color: var(--color-text-muted);
    color: var(--color-text-muted);
    cursor:inherit;
}
.slider__arrow.arrow-prev {
    left: calc(-30rem/16);
    top: 90%;
    transform: translateX(-100%) translateY(-50%);
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        top: 85%;
        left: calc(-55rem/16);
        transform: none;
    }
    @media screen and (max-width: 767px) {
        top: 42%;
        left: calc(10rem/16);
        transform: none;
    }
}
.slider__arrow.arrow-next {
    right: calc(-30rem/16);
    top: 90%;
    transform: translateX(100%) translateY(-50%);
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        top: 85%;
        right: calc(-55rem/16);
        transform: none;
    }
    @media screen and (max-width: 767px) {
        top: 42%;
        right: calc(10rem/16);
        transform: none;
    }
}
.slider__arrow:focus {
    outline:none;
}

@keyframes pulse-arrow {
    0% {
        box-shadow: 0 0 0 0 rgba(20, 140, 177, 0.4);
    }
    70% {
        box-shadow: 0 0 0 15px rgba(204,169,44, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}

/* lightbox icons */
.lg-actions .lg-icon.lg-next:before {
    content: ">";
}
.lg-actions .lg-icon.lg-prev:after {
    content: "<";
}
.lg-toolbar .lg-icon.lg-close:after {
    font-family: var(--font-default);
    content: "X";
}
