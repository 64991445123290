.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
    margin-top: calc(20rem/16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg ul li {
    margin: calc(10rem/16) 0;
    list-style: none;
}
.wysiwyg ul li:before {
    content: "•";
    font-size: calc(17.5rem/16);
    vertical-align: top;
    padding-right: calc(15rem/16);
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}