.img-teaser {
    position: relative;
}
.img-teaser__content {
    position: absolute;
    left: calc(30rem/16);
    bottom: calc(30rem/16);
    z-index: 1;

    @media screen and (max-width: 767px) {
        left: calc(15rem/16);
        bottom: calc(15rem/16);
    }
}

.img-teaser__img {
    position: relative;
    overflow: hidden;
}
.img-teaser__img img {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}
.img-teaser__img::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(60, 63, 65);
    opacity: 0.15;
    z-index: 1;
}

/* hover effects */
@media screen and (min-width: 1200px) {
    .img-teaser:hover {
        cursor: pointer;
    }
    .img-teaser:hover .img-teaser__img img {
        transform: scale(1.2);
        transition: transform 0.3s ease-in-out;
    }
}