html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    margin: 0;
    overflow-x: hidden;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(850rem/16);
    width: 100%;
}
@media screen and (min-width: 1200px) {
    .container.container {
        max-width: calc(1140rem/16);
    }
}
.container.container-small {
    max-width: calc(850rem/16);
    width: 100%;
}
@media screen and (min-width: 1200px) {
    .container.container-small {
        max-width: calc(860rem/16);
    }
}