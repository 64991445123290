form {
    margin-top: calc(20rem/16);
}

/* groups */
.form-group {
    margin-bottom: calc(26rem/16);
}

/* inputs */
.form-control {
    color: var(--color-primary);
    padding: calc(28rem/16) calc(16rem/16);
    background-color: rgba(242, 242, 242, 0.7);
    border: none;
    border-radius: 0;
}
textarea.form-control {
    padding: calc(18rem/16) calc(16rem/16);
    resize: none;
}
.form-control:focus {
    background-color: rgba(242, 242, 242, 0.7);
}
::placeholder {
    color: var(--color-text-muted);
    opacity: 1;
}

/* select */
select.form-control {
    appearance: none;
    display: block;
    height: 100%;
    padding: calc(18rem/16) calc(16rem/16);
}

select.form-control option {
    color: var(--color-primary);
}
/* select arrow */
.select.form-group {
    position: relative;
}
.select.form-group::before {
    content: '';
    position: absolute;
    right: calc(15rem/16);
    top: calc(50% - 6px);
    width: calc(10rem/16);
    height: calc(10rem/16);
    border-top: 2px solid var(--color-primary);
    border-right: 2px solid var(--color-primary);
    transform: rotate(135deg);
    pointer-events: none;
}

/* IE bug stuff */
select.form-control::-ms-expand {
    display: none;
}
select:focus::-ms-value {
    background-color: transparent;
}

/* recaptcha */
.g-recaptcha {
    margin-bottom: calc(26rem/16);
}