/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-primary { color: var(--color-primary); }
.text-secondary { color: var(--color-secondary); }
.text-success { color: var(--color-success); }
.text-danger { color: var(--color-danger); }
.text-shadow { text-shadow: 0 4px 5px rgba(0, 0, 0, 0.25); }

/* Background Color Helper */
.bg-light-grey { background-color: var(--color-light-grey); }
.bg-primary { background-color: var(--color-primary); }

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.overflow-hidden-sm {
    @media screen and (max-width: 1199px) {
        overflow: hidden;
    }
}