footer, .footer {
    margin-top: calc(130rem/16);
    padding: 50px 0;
}
@media screen and (max-width: 767px) {
    footer, .footer {
        margin-top: calc(60rem/16);
    }
}
.footer__logo {
    height: calc(45rem/16);
    width: calc(45rem/16);
    margin-bottom: calc(18rem/16);
}